//  libraries
import styled from "styled-components";

//  hooks

//  contexts
import { NavigationProvider } from "../contexts/NavigationContext";

//  components
import { Outlet } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import WhatsAppWidget from "react-whatsapp-chat-widget";

//  styles
// import { typographies } from "../components/common/typographies";
import "react-whatsapp-chat-widget/index.css";
import { useLanguage } from "../hooks/useLanguage";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Page = styled.div`
  @font-face {
    font-family: "BodyTypography";
    src: url(typographies.bodyTypography);
  }

  font-family: "BodyTypography";
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: whitesmoke;
`;

const Main = () => {
  const { locale } = useLanguage();

  const widgetProps = {
    phoneNo: "+526622811402",
    headerTitle: "H1 Help Desk",
    chatPersonName: "H1 Help Desk",
    chatMessage:
      locale === "es-MX"
        ? "Hola, ¿cómo podemos ayudarle?"
        : "Hello, how can we help you?",
    headerBgColor: "rgb(40,40,40)",
    bodyBgColor: "rgb(100,100,100)",
    footerBgColor: "rgb(40,40,40)",
    btnBgColor: "whitesmoke",
    btnTxt: locale === "es-MX" ? "Comienza un chat" : "Start a chat",
    btnTxtColor: "rgb(40,40,40)",
  };
  return (
    <Wrapper>
      <NavigationProvider>
        <Header />
        <Page>
          <Outlet />
        </Page>
        <WhatsAppWidget {...widgetProps} />
        <Footer />
      </NavigationProvider>
    </Wrapper>
  );
};

export default Main;
