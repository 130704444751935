//  hooks
import { useState, useEffect } from "react";

export const useFade = (ref) => {
  const [isTransparent, setIsTransparent] = useState(true);
  const [threshold, setThreshold] = useState();

  useEffect(() => {
    setThreshold(ref.current.offsetTop - 100);
  }, [ref]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const pageOffset = window.pageYOffset;
      pageOffset > threshold ? setIsTransparent(false) : setIsTransparent(true);
    });
  }, [threshold]);

  return isTransparent;
};
