//  libraries
import styled from "styled-components";

//  hooks
import { useLanguage } from "../hooks/useLanguage";

//  components
import { Outlet } from "react-router-dom";
import HeaderAlt from "../components/Header/HeaderAlt";
import Footer from "../components/Footer/Footer";
import WhatsAppWidget from "react-whatsapp-chat-widget";

//  styles
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: whitesmoke;
`;

const Alternate = () => {
  const { locale } = useLanguage();

  const widgetProps = {
    phoneNo: "+526622811402",
    headerTitle: "H1 Help Desk",
    chatPersonName: "H1 Help Desk",
    chatMessage:
      locale === "es-MX"
        ? "Hola, ¿cómo podemos ayudarle?"
        : "Hello, how can we help you?",
    headerBgColor: "rgb(40,40,40)",
    bodyBgColor: "rgb(100,100,100)",
    footerBgColor: "rgb(40,40,40)",
    btnBgColor: "whitesmoke",
    btnTxt: locale === "es-MX" ? "Comienza un chat" : "Start a chat",
    btnTxtColor: "rgb(40,40,40)",
  };
  return (
    <Wrapper>
      <HeaderAlt />
      <Page>
        <Outlet />
      </Page>
      <WhatsAppWidget {...widgetProps} />
      <Footer />
    </Wrapper>
  );
};

export default Alternate;
