//  libraries
import styled from "styled-components";

//  assets
import { typographies } from "../common/typographies";

export const Bar = styled.div`
  @font-face {
    font-family: "BodyTypography";
    src: url(${typographies.bodyTypography});
  }

  font-family: "BodyTypography";
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 1rem;
  color: whitesmoke;
  background-color: rgb(20, 20, 20);
  border-top-style: solid;
  border-width: 1px;
  z-index: 8;

  @media screen and (max-width: 650px) {
    flex-direction: column;
  }
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  font-size: 0.9rem;
  padding: 0 1rem;

  @media screen and (max-width: 650px) {
    text-align: center;
    margin: 2rem;
  }
`;

export const OtherPages = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  height: 10rem;
  padding: 1rem;
  font-size: 0.9rem;
  border-left-style: solid;
  border-right-style: solid;
  border-color: rgba(100, 100, 100, 0.8);
  border-width: 1px;

  @media screen and (max-width: 650px) {
    border-left-style: none;
    border-right-style: none;
    border-top-style: solid;
    border-bottom-style: solid;
  }
`;
