//  hooks
import { forwardRef } from "react";
import { useNavBar } from "../../hooks/useNavBar";

//  components
import Logo from "../Logo/Logo";
import { FormattedMessage } from "react-intl";

//  assets
import { heroVideo } from "../../assets/videos";

//  styles
import { Wrapper, Overlay, Content, Copy, CTAButton, Video } from "./styles";

const Hero = forwardRef((props, ref) => {
  const { contact } = useNavBar();

  return (
    <Wrapper ref={ref} height={"100vh"}>
      <Overlay>
        <Content>
          <Logo type="isotype" size={"18rem"} />
          <Copy>
            <FormattedMessage id="hero.heading" />
          </Copy>
          <CTAButton onClick={contact.scroll}>
            <FormattedMessage id="hero.button" />
          </CTAButton>
        </Content>
      </Overlay>
      <Video
        alt="hero video"
        src={heroVideo}
        loading="lazy"
        autoPlay
        muted
        loop
      />
    </Wrapper>
  );
});

export default Hero;
