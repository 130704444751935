//  hooks
import { useState } from "react";
import { useNavBar } from "../../hooks/useNavBar";
// import { useFade } from "../../hooks/useFade";

//  components
import ServicesMenuAlt from "../ServicesMenu/ServicesMenuAlt";
import { FormattedMessage } from "react-intl";

//  assets
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

//  styles
import { NavItem, Menu } from "./styles";

const NavDropdown = () => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const { hero, services, spaces, community, aboutUs, contact, active } =
    useNavBar();
  return (
    <Menu>
      <NavItem onClick={hero.scroll} selected={active === "hero" && true}>
        <FormattedMessage id="header.nav.home" />
      </NavItem>
      <NavItem
        onClick={services.scroll}
        selected={active === "services" && true}
      >
        <FormattedMessage id="header.nav.services" />
        {openDropdown ? (
          <KeyboardArrowDownIcon
            onClick={() => setOpenDropdown(!openDropdown)}
          />
        ) : (
          <KeyboardArrowRightIcon
            onClick={() => setOpenDropdown(!openDropdown)}
          />
        )}
      </NavItem>
      {openDropdown && <ServicesMenuAlt />}
      <NavItem onClick={spaces.scroll} selected={active === "spaces" && true}>
        <FormattedMessage id="header.nav.spaces" />
      </NavItem>
      <NavItem
        onClick={community.scroll}
        selected={active === "community" && true}
      >
        <FormattedMessage id="header.nav.community" />
      </NavItem>
      <NavItem onClick={aboutUs.scroll} selected={active === "aboutUs" && true}>
        <FormattedMessage id="header.nav.aboutUs" />
      </NavItem>
      <NavItem onClick={contact.scroll} selected={active === "contact" && true}>
        <FormattedMessage id="header.nav.contact" />
      </NavItem>
    </Menu>
  );
};

export default NavDropdown;
