//  libraries
import { useState } from "react";

//  hooks
import { useNavBar } from "../../hooks/useNavBar";

//  components
import ServicesMenu from "../ServicesMenu/ServicesMenu";
import { FormattedMessage } from "react-intl";

//  assets
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Wrapper, NavItem } from "./styles";

const NavBar = () => {
  const { hero, services, spaces, community, aboutUs, contact, active } =
    useNavBar();

  const [openDropdown, setOpenDropdown] = useState(false);

  return (
    <Wrapper>
      <NavItem onClick={hero.scroll} selected={active === "hero" && true}>
        <FormattedMessage id="header.nav.home" />
      </NavItem>
      <NavItem
        onClick={services.scroll}
        onMouseEnter={() => setOpenDropdown(true)}
        onMouseLeave={() => setOpenDropdown(false)}
        selected={active === "services" && true}
      >
        <FormattedMessage id="header.nav.services" />
        <KeyboardArrowDownIcon />
        {openDropdown && <ServicesMenu />}
      </NavItem>
      <NavItem onClick={spaces.scroll} selected={active === "spaces" && true}>
        <FormattedMessage id="header.nav.spaces" />
      </NavItem>
      <NavItem
        onClick={community.scroll}
        selected={active === "community" && true}
      >
        <FormattedMessage id="header.nav.community" />
      </NavItem>
      <NavItem onClick={aboutUs.scroll} selected={active === "aboutUs" && true}>
        <FormattedMessage id="header.nav.aboutUs" />
      </NavItem>
      <NavItem onClick={contact.scroll} selected={active === "contact" && true}>
        <FormattedMessage id="header.nav.contact" />
      </NavItem>
    </Wrapper>
  );
};

export default NavBar;
