//  components
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

//  styles
import { Menu, MenuItem, linkStyle } from "./styles";

const ServicesMenu = () => {
  return (
    <Menu>
      <Link to={"/service/private-offices"} style={linkStyle}>
        <MenuItem>
          <FormattedMessage id="header.nav.services.privateOffices" />
        </MenuItem>
      </Link>
      <Link to={"/service/boardroom"} style={linkStyle}>
        <MenuItem>
          <FormattedMessage id="header.nav.services.boardroom" />
        </MenuItem>
      </Link>
      <Link to={"/service/coworking"} style={linkStyle}>
        <MenuItem>
          <FormattedMessage id="header.nav.services.coworking" />
        </MenuItem>
      </Link>
      <Link to={"/service/virtual-office"} style={linkStyle}>
        <MenuItem>
          <FormattedMessage id="header.nav.services.virtualOffice" />
        </MenuItem>
      </Link>
    </Menu>
  );
};

export default ServicesMenu;
