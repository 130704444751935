//  hooks
import { useFade } from "../../hooks/useFade";
import { useNavBar } from "../../hooks/useNavBar";
import { useWindowSize } from "use-hooks";

//  components
import Logo from "../Logo/Logo";
import NavBar from "../NavBar/NavBar";
import NavBarAlt from "../NavBar/NavBarAlt";
import LanguageSelector from "../LanguageSelector/LanguageSelector";

//  styles
import { Bar, MobileWrapper } from "./styles";

const Header = () => {
  const { services } = useNavBar();
  const isTransparent = useFade(services.ref);
  const windowSize = useWindowSize();

  return (
    <Bar opacityLevel={isTransparent}>
      <Logo />
      {windowSize.width > 970 ? (
        <>
          <NavBar />
          <LanguageSelector />
        </>
      ) : (
        <MobileWrapper>
          <LanguageSelector />
          <NavBarAlt />
        </MobileWrapper>
      )}
    </Bar>
  );
};

export default Header;
