//  libraries
import styled from "styled-components";

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 4.1rem;
  padding: 0.2rem;
  box-shadow: 3px 3px 5px rgb(50, 50, 50);
  border-width: 3px;
  border-color: rgb(30, 30, 30);
  border-radius: 3px;
  border-top-style: solid;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  width: 7rem;
  height: fit-content;
  background-color: whitesmoke;
  z-index: 2;
`;

export const MenuItem = styled.div`
  display: flex;
  column-gap: 5px;
  align-items: center;
  border-radius: 3px;
  padding: 0.5rem;
  font-size: 0.9rem;

  &:hover {
    background-color: rgb(30, 30, 30);
    color: whitesmoke;
  }
`;

export const MenuAlt = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.2rem;
  width: 7rem;
  height: fit-content;
  z-index: 2;
`;

export const MenuItemAlt = styled.div`
  display: flex;
  column-gap: 5px;
  color: whitesmoke;
  align-items: center;
  border-radius: 3px;
  padding: 0.5rem;
  margin-left: 0.5rem;
  font-size: 0.9rem;
  font-weight: bold;

  &:hover {
    color: rgb(30, 30, 30);
    background-color: whitesmoke;
  }
`;

export const linkStyle = {
  color: "rgb(30, 30, 30)",
  fontWeight: "bold",
  textDecoration: "none",
};
