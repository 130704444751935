//  components
import Logo from "../Logo/Logo";
import LanguageSelector from "../LanguageSelector/LanguageSelector";

//  styles
import { Bar } from "./styles";

const HeaderAlt = () => {
  return (
    <Bar>
      <Logo />
      <LanguageSelector />
    </Bar>
  );
};

export default HeaderAlt;
