//  libraries
import styled from "styled-components";

//  fonts
import { typographies } from "./typographies";

//  styles
export const Title = styled.h1`
  @font-face {
    font-family: "HeaderTypography";
    src: url(${typographies.headerTypography});
  }

  font-family: "HeaderTypography";
  text-align: center;
  font-size: 2.5rem;
  padding: 1rem;
  margin-top: 1rem;
  align-self: center;
`;

export const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;

  @media screen and (max-width: 650px) {
    justify-content: center;
  }
`;

export const linkStyle = {
  color: "whitesmoke",
  fontWeight: "bold",
  textDecoration: "none",
};
