//  components
import Logo from "../Logo/Logo";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

//  assets
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

//  styles
import { SocialMedia } from "../common/common";
// import { typographies } from "../common/typographies";
import { linkStyle } from "../common/common";
import { Bar, Contact, OtherPages } from "./styles";

const Footer = () => {
  return (
    <Bar>
      <Logo type="isotype" size={"9.5rem"} />

      <OtherPages>
        <Link to="/" style={linkStyle}>
          <FormattedMessage id="footer.links.home" />
        </Link>
        <div>•</div>
        <Link to="/service/private-offices" style={linkStyle}>
          <FormattedMessage id="footer.links.privateOffices" />
        </Link>
        <div>•</div>
        <Link to="/service/boardroom" style={linkStyle}>
          <FormattedMessage id="footer.links.boardroom" />
        </Link>
        <div>•</div>
        <Link to="/service/coworking" style={linkStyle}>
          <FormattedMessage id="footer.links.coworking" />
        </Link>
        {/* <div>•</div>
        <Link to="/terms-of-use" style={linkStyle}>
          Términos de uso
        </Link>
        <div>•</div>
        <Link to="/policies" style={linkStyle}>
          Políticas de privacidad
        </Link> */}
      </OtherPages>

      <Contact>
        <div>Calle Higueras #4, Col. Fuentes del Mezquital</div>
        <div>Hermosillo, Sonora</div>
        <SocialMedia>
          <FacebookIcon
            onClick={() =>
              window.open("https://www.facebook.com/H1.coworking/")
            }
          />
          <InstagramIcon
            onClick={() =>
              window.open("https://www.instagram.com/h1.coworking/")
            }
          />
          h1.coworking
        </SocialMedia>
        <div>Tel (662) 438 1580</div>
        <div>Cel (622) 281 1402</div>
        <div>coworking.h1@gmail.com</div>
      </Contact>
    </Bar>
  );
};

export default Footer;
