//  libraries
import styled from "styled-components";

//  assets
import { typographies } from "../common/typographies";

export const CarouselWrapper = styled.div`
  height: 70vh;
  width: 100vw;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 80%,
    rgba(255, 255, 255, 0)
  );
  color: whitesmoke;
  margin-bottom: 1rem;
  border-radius: 3px;
  overflow: hidden;
`;

export const SlideWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 65vh;
  z-index: 0;
  overflow: hidden;
`;

export const Overlay = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 0 15vw;
  width: 100vw;
  height: 65vh;
  background-color: rgba(30, 30, 30, 0.8);
  z-index: 2;

  @media screen and (max-width: 650px) {
    justify-content: space-between;
    flex-direction: column;
    padding: 0 8vw;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 2rem;
  padding: 4rem 2rem;

  @media screen and (max-width: 650px) {
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
  }
`;

export const Copy = styled.h1`
  @font-face {
    font-family: "HeaderTypography";
    src: url(${typographies.headerTypography});
  }

  font-family: "HeaderTypography";
  color: whitesmoke;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  max-width: 40vw;

  @media screen and (max-width: 650px) {
    max-width: 80vw;
  }
`;

export const Description = styled.ul`
  display: flex;
  flex-direction: column;
  color: whitesmoke;
  font-size: 1rem;
  font-weight: bold;
  max-width: 40vw;
  row-gap: 0.8rem;
  list-style-type: disc;

  @media screen and (max-width: 650px) {
    max-width: 80vw;
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  column-gap: 1rem;
  height: 100%;
  width: 100%;
  row-gap: 2rem;
  padding: 2rem;

  @media screen and (max-width: 650px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 0rem;
  }
`;

export const CTAButton = styled.button`
  @font-face {
    font-family: "bodyTypography";
    src: url(${typographies.bodyTypography});
  }

  font-family: "bodyTypography";
  display: flex;
  justify-content: center;
  font-size: 0.9rem;
  width: 8rem;
  padding: 0.7rem;
  font-weight: bold;
  border-style: none;
  border-radius: 5px;
  color: rgb(30, 30, 30);
  background-color: whitesmoke;
  box-shadow: 3px 3px 5px rgb(50, 50, 50);

  &:hover {
    background-color: rgb(30, 30, 30);
    color: whitesmoke;
    text-shadow: 2px 2px 2px rgb(10, 10, 10);
    cursor: pointer;
  }
`;

export const Photo = styled.img`
  position: absolute;
  width: 100vw;
  height: 65vh;
  object-fit: cover;
  z-index: 1;
`;
