//  hooks
import { forwardRef } from "react";

//  components
import ServicesCarousel from "../ServicesCarousel/ServicesCarousel";
import { FormattedMessage } from "react-intl";

//  styles
import { Title } from "../common/common";
import { Wrapper } from "./styles";

const Services = forwardRef((props, ref) => {
  return (
    <Wrapper ref={ref}>
      <Title>
        <FormattedMessage id="services.heading" />
      </Title>
      <ServicesCarousel />
    </Wrapper>
  );
});

export default Services;
