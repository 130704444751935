//  hooks
import { useState, createContext, useEffect } from "react";

//  components
import { IntlProvider } from "react-intl";

//  assets
import English from "../assets/languages/en-US.json";
import Spanish from "../assets/languages/es-MX.json";

const LanguageContext = createContext({});

export const LanguageProvider = ({ children }) => {
  const [locale, setLocale] = useState(
    localStorage.getItem("language") || "es-MX"
  );
  const [messages, setMessages] = useState(
    locale === "es-MX" ? Spanish : English
  );

  useEffect(() => {
    if (locale === "es-MX") setMessages(Spanish);
    if (locale === "en-US") setMessages(English);
  }, [locale]);

  const selectLanguage = (e) => {
    const newLocale = e.target.value;
    setLocale(newLocale);
    if (newLocale === "es-MX") {
      setMessages(Spanish);
      localStorage.setItem("language", "es-MX");
    } else if (newLocale === "en-US") {
      setMessages(English);
      localStorage.setItem("language", "en-US");
    }
  };

  return (
    <LanguageContext.Provider value={{ locale, selectLanguage }}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
