//  libraries
import styled from "styled-components";

//  assets
import { typographies } from "../common/typographies";

export const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background-color: rgb(30, 30, 30);
  overflow: hidden;
`;

export const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 0 10vw;
  width: 100vw;
  height: 100%;
  background-image: linear-gradient(
    to right,
    rgba(30, 30, 30, 0.9) 20%,
    rgba(30, 30, 30, 0.4)
  );
  z-index: 10;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60vw;
`;

export const Video = styled.video`
  width: 100vw;
  height: 100%;
  object-fit: cover;
  z-index: 5;
`;

export const Copy = styled.h1`
  @font-face {
    font-family: "HeaderTypography";
    src: url(${typographies.headerTypography});
  }

  font-family: "HeaderTypography";
  color: whitesmoke;
  text-align: center;
  font-size: 3.6rem;
  width: 100vw;
  padding: 0 5vw;
  text-shadow: 5px 5px 5px rgb(50, 50, 50);
`;

export const CTAButton = styled.button`
  @font-face {
    font-family: "bodyTypography";
    src: url(${typographies.bodyTypography});
  }

  font-family: "bodyTypography";
  display: flex;
  justify-content: center;
  font-size: 1rem;
  width: fit-content;
  padding: 1rem;
  margin: 3rem;
  font-weight: bold;
  border-style: none;
  border-radius: 5px;
  color: rgb(30, 30, 30);
  background-color: whitesmoke;
  text-shadow: 2px 2px 2px rgb(150, 150, 150);
  box-shadow: 3px 3px 5px rgb(50, 50, 50);

  &:hover {
    background-color: rgb(30, 30, 30);
    color: whitesmoke;
    cursor: pointer;
    text-shadow: 2px 2px 2px rgb(10, 10, 10);
  }
`;
