//  hooks
import { useState } from "react";

//  components
import NavDropdown from "./NavDropdown";

//  assets
import MenuIcon from "@mui/icons-material/Menu";

const NavBarAlt = () => {
  const [openDropdown, setOpenDropdown] = useState(false);

  return (
    <>
      <MenuIcon onClick={() => setOpenDropdown(!openDropdown)} />
      {openDropdown && <NavDropdown />}
    </>
  );
};

export default NavBarAlt;
